import { Button, Typography } from '@mui/material';
import useLocales from '#/hooks/useLocales';

type ActionButtonsProps = {
  handleShowForm: () => void;
};

export default function ActionButtons({ handleShowForm }: ActionButtonsProps) {
  const { translate } = useLocales();

  return (
    <Button variant="contained" onClick={handleShowForm}>
      <Typography>{String(translate(`digitalAssets.addItem`))}</Typography>
    </Button>
  );
}
