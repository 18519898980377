export const AFTER_DEATH_OPTIONS = [
  {
    value: 'delete',
  },
  {
    value: 'inherit',
  },
];

export const ASSET_TYPES = [
  {
    value: 'email',
  },
  {
    value: 'social_media_account',
  },
  {
    value: 'membership',
  },
  {
    value: 'subscription',
  },
  {
    value: 'domain',
  },
  {
    value: 'royalty',
  },
  {
    value: 'crypto_wallet',
  },
  {
    value: 'device',
  },
  {
    value: 'password_manager',
  },
  {
    value: 'phone_provider',
  },
  {
    value: 'other',
  },
];
