import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import React, { useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-hot-toast';
import { DigitalAsset, FormValues } from '#/types/digitalAssets';
import Iconify from '#/components/shared/ui/Iconify';
import usePopover from '#/hooks/usePopover';
import useBoolean from '#/hooks/useBoolean';
import CustomPopover from '#/components/shared/custom-popover/custom-popover';
import useLocales from '#/hooks/useLocales';
import DigitalAssetsEditRow from '#/components/pages/DigitalAssets/table/digital-assets-edit-row';
import {
  invalidateDigitalAssetsQueries,
  useDeleteDigitalAsset,
  useUpdateDigitalAsset,
} from '#/api/digitalAssets';
import ConfirmDeleteModal from '#/components/pages/DigitalAssets/table/confirm-delete-modal';

type Props = {
  selected: boolean;
  row: any;
  readOnly?: boolean;
};

export default function DigitalAssetsTableRow({
  selected,
  row,
  readOnly,
}: Props) {
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);

  const handleEnableEdit = () => setEnableEdit((prev) => !prev);
  const handleOpenDeleteConfirmation = () => setOpenDeleteConfirmation(true);
  const handleCloseDeleteConfirmation = () => setOpenDeleteConfirmation(false);

  const { mutateAsync: updateDigitalAsset } = useUpdateDigitalAsset(row.id);
  const { mutateAsync: deteleDigitalAsset } = useDeleteDigitalAsset(row.id);

  const popover = usePopover();
  const confirm = useBoolean();
  const { translate } = useLocales();

  const onEdit = async (values: FormValues) => {
    await updateDigitalAsset(values)
      .then(() => {
        toast.success(
          String(translate('toast_notifications.success.editDigitalAsset'))
        );
        invalidateDigitalAssetsQueries.getDigitalAssets();
        handleEnableEdit();
      })
      .catch(() => {
        translate('toast_notifications.error.editDigitalAsset');
      });
  };

  const onDelete = async () => {
    await deteleDigitalAsset(row.id)
      .then(() => {
        toast.success(
          String(translate('toast_notifications.success.deleteDigitalAsset'))
        );
        invalidateDigitalAssetsQueries.getDigitalAssets();
      })
      .catch(() => {
        translate('toast_notifications.error.deleteDigitalAsset');
      });
  };

  return (
    <>
      {enableEdit ? (
        <DigitalAssetsEditRow
          initialValues={{
            ...row,
            beneficiary: row.beneficiary?.id || '',
          }}
          onSubmit={onEdit}
          onEditChange={handleEnableEdit}
        />
      ) : (
        <ReadOnlyRow
          row={row}
          selected={selected}
          popover={popover}
          disableOptions={readOnly}
        />
      )}
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 200 }}
      >
        <MenuItem
          onClick={() => {
            handleEnableEdit();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {String(translate('global.edit'))}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenDeleteConfirmation();
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {String(translate('global.delete'))}
        </MenuItem>
      </CustomPopover>
      <ConfirmDeleteModal
        open={openDeleteConfirmation}
        closeDialog={handleCloseDeleteConfirmation}
        onConfirm={onDelete}
        digitalAsset={{ name: row.name }}
      />
    </>
  );
}

type ReadOnlyProps = {
  row: DigitalAsset;
  selected: boolean;
  popover: ReturnType<typeof usePopover>;
  disableOptions?: boolean;
};

export function ReadOnlyRow({
  row,
  selected,
  popover,
  disableOptions,
}: ReadOnlyProps) {
  const { translate } = useLocales();
  const { name, access_info, asset_type, after_death, beneficiary } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Typography variant="subtitle1" fontWeight="light" textTransform="none">
          {name}
        </Typography>
      </TableCell>

      <TableCell>
        <Tooltip title={access_info}>
          <Typography
            variant="subtitle1"
            fontWeight="light"
            textTransform="none"
            noWrap
            sx={{ maxWidth: 200 }}
          >
            {access_info}
          </Typography>
        </Tooltip>
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Typography variant="subtitle1" fontWeight="light">
          {String(translate(`digitalAssets.assetTypes.${asset_type}`))}
        </Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Typography variant="subtitle1" fontWeight="light">
          {String(translate(`digitalAssets.afterDeathOptions.${after_death}`))}
        </Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap', textTransform: 'none' }}>
        <Typography variant="subtitle1" fontWeight="light">
          {beneficiary?.name || '-'}
        </Typography>
      </TableCell>
      {!disableOptions && (
        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton
            color={popover.open ? 'inherit' : 'default'}
            onClick={popover.onOpen}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
}
