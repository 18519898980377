const sortingModel = [
  {
    field: 'name',
    sort_by: {
      asc: 'name',
      desc: '-name',
    },
  },
  {
    field: 'asset_type',
    sort_by: {
      asc: 'asset_type',
      desc: '-asset_type',
    },
  },
  {
    field: 'after_death',
    sort_by: {
      asc: 'after_death',
      desc: '-after_death',
    },
  },
];

export default sortingModel;
