import React, { useCallback } from 'react';
import { Box, InputAdornment, MenuItem, Stack, TextField } from '@mui/material';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';
import { ASSET_TYPES } from '#/components/pages/DigitalAssets/formOptions';

type Props = {
  filters: {
    search: string;
    asset_type: string;
  };
  onFilters: (name: string, value: string | string[]) => void;
};

export default function DigitalAssetsTableToolbar({
  filters,
  onFilters,
}: Props) {
  const { translate } = useLocales();

  const handleFilterSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('search', event.target.value);
    },
    [onFilters]
  );

  return (
    <Stack
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      spacing={2}
    >
      <TextField
        size="small"
        value={filters.search}
        onChange={handleFilterSearch}
        placeholder={String(translate('global.formLabels.search'))}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        select
        fullWidth
        size="small"
        label={String(translate('global.formLabels.assetType'))}
        value={filters.asset_type}
        onChange={(event) =>
          onFilters('asset_type', event.target.value as string)
        }
        sx={{
          minWidth: 160,
        }}
      >
        {ASSET_TYPES.map((type) => (
          <MenuItem key={type.value} value={type.value}>
            {String(translate(`digitalAssets.assetTypes.${type.value}`))}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
}
