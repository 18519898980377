import { Card, Stack, Table, TableBody, TableContainer } from '@mui/material';
import React, { useCallback, useState } from 'react';
import isEqual from 'lodash/isEqual';
import DigitalAssetsTableToolbar from '#/components/pages/DigitalAssets/table/digital-assets-table-toolbar';
import useTable from '#/hooks/useTable';
import useDebounce from '#/hooks/useDebounce';
import sortingModel from '#/components/pages/DigitalAssets/table/sortingModel';
import { useGetDigitalAssets } from '#/api/digitalAssets';
import Scrollbar from '#/components/shared/Scrollbar';
import TableHeadCustom from '#/components/shared/table/table-head-custom';
import DigitalAssetsTableRow from '#/components/pages/DigitalAssets/table/digital-assets-table-row';
import TableEmptyRows from '#/components/shared/table/table-empty-rows';
import { emptyRows } from '#/components/shared/table/utils';
import TableNoData from '#/components/shared/table/tabel-empty-rows';
import TablePaginationCustom from '#/components/shared/table/table-pagination-custom';
import ActionButtons from '#/components/pages/DigitalAssets/table/action-buttons';
import DigitalAssetsFilterResults from '#/components/pages/DigitalAssets/table/digital-assets-filter-results';
import useLocalStorage from '#/hooks/useLocalStorage';

type Props = {
  handleShowForm: () => void;
};

export default function DigitalAssetsTable({ handleShowForm }: Props) {
  const [filters, setFilters] = useState(defaultFilters);

  const table = useTable();
  const debouncedSearch = useDebounce(filters.search, 500);
  const hasFilters = filters?.search.length > 0 || debouncedSearch;
  const [ownerID] = useLocalStorage('ownerID', undefined);

  const handleSorting = () => {
    const requestedField = sortingModel.find(
      (element) => element.field === table.orderBy
    );
    return requestedField?.sort_by[table.order];
  };

  const handleFilters = useCallback(
    (name: string, value: string | string[]) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const { data: digitalAssetsList } = useGetDigitalAssets(
    hasFilters ? 1 : table.page + 1,
    ownerID || undefined,
    debouncedSearch || undefined,
    filters.asset_type || undefined,
    table.order ? handleSorting() : undefined,
    true
  );

  const canReset = !isEqual(defaultFilters, filters);

  const notFound =
    (!digitalAssetsList?.results?.length && canReset) ||
    !digitalAssetsList?.results?.length;

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  return (
    <Card>
      <Stack
        direction={{
          xs: 'column',
          md: 'row',
        }}
        spacing={2}
        sx={{ p: 2 }}
        justifyContent={{ xs: 'center', md: 'space-between' }}
      >
        <Stack direction="row">
          <DigitalAssetsTableToolbar
            filters={filters}
            onFilters={handleFilters}
          />
          {canReset && (
            <DigitalAssetsFilterResults
              filters={filters}
              onFilters={handleFilters}
              onResetFilters={handleResetFilters}
              results={digitalAssetsList?.count || 0}
            />
          )}
        </Stack>
        {!ownerID && <ActionButtons handleShowForm={handleShowForm} />}
      </Stack>
      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar
          styledRootScrollbarSx={{
            height: digitalAssetsList?.count === 0 ? '450px' : '300px',
            overflowY: 'scroll',
          }}
        >
          <Table size="small" sx={{ minWidth: 960 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_HEAD}
              onSort={table.onSort}
            />
            <TableBody>
              {digitalAssetsList?.results?.map((row) => (
                <DigitalAssetsTableRow
                  key={row.id}
                  row={row}
                  selected={table.selected.includes(String(row.id))}
                  readOnly={ownerID}
                />
              ))}
              <TableEmptyRows
                height={0}
                emptyRows={emptyRows(
                  table.page,
                  table.rowsPerPage,
                  digitalAssetsList?.results?.length || 0
                )}
              />
            </TableBody>
            <TableNoData notFound={notFound} />
          </Table>
        </Scrollbar>
      </TableContainer>
      <TablePaginationCustom
        count={digitalAssetsList?.count || 0}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table.onChangePage}
        onRowsPerPageChange={table.onChangeRowsPerPage}
        //
        dense={table.dense}
        onChangeDense={table.onChangeDense}
      />
    </Card>
  );
}

const defaultFilters = {
  search: '',
  asset_type: '',
};

const TABLE_HEAD = [
  {
    id: 'name',
    label: 'digitalAssets.assetsTable.tableHeads.name',
  },
  {
    id: 'access',
    label: 'digitalAssets.assetsTable.tableHeads.access',
  },
  {
    id: 'type',
    label: 'digitalAssets.assetsTable.tableHeads.type',
  },
  {
    id: 'after_death',
    label: 'digitalAssets.assetsTable.tableHeads.after_death',
  },
  {
    id: 'beneficiary',
    label: 'digitalAssets.assetsTable.tableHeads.beneficiary',
  },
  {
    id: '',
  },
];
