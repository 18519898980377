import { Stack, Typography, Box, Tooltip } from '@mui/material';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';

export default function DownloadPasswordStorage() {
  const { translate } = useLocales();

  return (
    <Box sx={{ marginY: 4 }}>
      <WarningMessage
        message={String(translate('digitalAssets.passwordWarning'))}
        tooltip={String(translate('digitalAssets.passwordWarningTooltip'))}
      />
      <DownloadPasswordTemplate
        text={String(translate('digitalAssets.downloadTemplate'))}
      />
    </Box>
  );
}

type DownloadPasswordStorageProps = {
  text: string;
};

const DownloadPasswordTemplate = ({ text }: DownloadPasswordStorageProps) => {
  const handleDownloadPasswordManagerTemplate = () => {
    window
      .open(
        'https://storage.gutgeregelt.ch/docs/Passwort_Vorlage.docx',
        '_blank'
      )
      ?.focus();
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      justifyContent="center"
    >
      <Typography
        variant="subtitle1"
        color="primary.main"
        sx={{ cursor: 'pointer' }}
        onClick={handleDownloadPasswordManagerTemplate}
      >
        {text}
      </Typography>
    </Stack>
  );
};

type MessageProps = {
  message: string;
  tooltip: string;
};

const WarningMessage = ({ message, tooltip }: MessageProps) => (
  <Stack
    direction="row"
    alignItems="center"
    spacing={1}
    justifyContent="center"
  >
    <Typography variant="subtitle1" color="error">
      {message}
    </Typography>
    <HtmlTooltip title={tooltip}>
      <Iconify
        icon="uiw:information"
        width={20}
        height={20}
        sx={{
          color: 'error.main',
        }}
      />
    </HtmlTooltip>
  </Stack>
);

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    maxWidth: 244,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}));
