import { Button, Paper, Stack, Typography } from '@mui/material';
import DialogAnimate from '#/components/shared/animate/DialogAnimate';
import useLocales from '#/hooks/useLocales';

type Props = {
  open: boolean;
  closeDialog: () => void;
  onConfirm: () => void;
  digitalAsset: {
    name: string;
  };
};

export default function ConfirmDeleteModal({
  open,
  closeDialog,
  onConfirm,
  digitalAsset,
}: Props) {
  const { translate } = useLocales();

  const { name } = digitalAsset;

  return (
    <DialogAnimate open={open} onClose={closeDialog}>
      <Paper
        sx={{
          padding: 2,
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          {String(
            translate('digitalAssets.confirmDeleteModal.title', {
              asset: name,
            })
          )}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {String(translate('digitalAssets.confirmDeleteModal.description'))}
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          spacing={2}
          justifyContent="center"
          sx={{ marginTop: 3 }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={closeDialog}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography variant="body2">
              {String(translate('digitalAssets.confirmDeleteModal.onClose'))}
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={onConfirm}
            size="small"
            sx={{ width: 1 }}
          >
            <Typography variant="body2">
              {String(translate('digitalAssets.confirmDeleteModal.onConfirm'))}
            </Typography>
          </Button>
        </Stack>
      </Paper>
    </DialogAnimate>
  );
}
