import React, { useEffect, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';
import {
  AFTER_DEATH_OPTIONS,
  ASSET_TYPES,
} from '#/components/pages/DigitalAssets/formOptions';
import { useGetBeneficiaries } from '#/api/beneficiaries';

type ValuesType = {
  name: string;
  access_info: string;
  asset_type: string;
  after_death: string;
  beneficiary: string;
};

type EditableRowProps = {
  initialValues: ValuesType;
  onSubmit: (values: ValuesType) => void;
  onEditChange: () => void;
};

const DigitalAssetsEditRow = ({
  initialValues,
  onSubmit,
  onEditChange,
}: EditableRowProps) => {
  const [removeBeneficiary, setRemoveBeneficiary] = useState(false);
  const [values, setValues] = useState<ValuesType>(initialValues);

  const { data: beneficiaries } = useGetBeneficiaries();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((prev) => ({
      ...prev,
      [name as keyof ValuesType]: value,
    }));
  };

  const fields: Array<{ name: keyof ValuesType; placeholder: string }> = [
    { name: 'name', placeholder: 'Name' },
    { name: 'access_info', placeholder: 'Access Info' },
    { name: 'asset_type', placeholder: 'Asset Type' },
    { name: 'after_death', placeholder: 'After Death' },
    { name: 'beneficiary', placeholder: 'Beneficiary' },
  ];

  useEffect(() => {
    if (values.beneficiary === '' && values.after_death !== 'inherit') {
      setRemoveBeneficiary(true);
    } else {
      setRemoveBeneficiary(false);
    }
  }, [values]);

  const { translate } = useLocales();

  return (
    <TableRow>
      {fields.map((field) => (
        <TableCell key={field.name}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {field.name === 'asset_type' ? (
            <Select
              // @ts-ignore
              onChange={handleOnChange}
              name={field.name}
              value={values[field.name]}
              fullWidth
              size="small"
            >
              {ASSET_TYPES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {String(
                    translate(`digitalAssets.assetTypes.${option.value}`)
                  )}
                </MenuItem>
              ))}
            </Select>
          ) : // eslint-disable-next-line no-nested-ternary
          field.name === 'after_death' ? (
            <Select
              // @ts-ignore
              onChange={handleOnChange}
              name={field.name}
              value={values[field.name]}
              fullWidth
              size="small"
            >
              {AFTER_DEATH_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {String(
                    translate(`digitalAssets.afterDeathOptions.${option.value}`)
                  )}
                </MenuItem>
              ))}
            </Select>
          ) : field.name === 'beneficiary' ? (
            <TextField
              select
              // @ts-ignore
              onChange={handleOnChange}
              name={field.name}
              value={values[field.name]}
              fullWidth
              size="small"
              disabled={values.after_death !== 'inherit'}
              sx={{
                display: removeBeneficiary ? 'none' : 'block',
              }}
              label={String(translate('global.beneficiary'))}
            >
              {beneficiaries?.map((beneficiary) => (
                <MenuItem key={beneficiary.id} value={beneficiary.id}>
                  {beneficiary.name}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <TextField
              size="small"
              placeholder={field.placeholder}
              name={field.name}
              value={values[field.name]}
              onChange={handleOnChange}
              fullWidth
            />
          )}
        </TableCell>
      ))}
      <TableCell>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Tooltip title={String(translate('global.save'))}>
            <IconButton onClick={() => onSubmit(values)}>
              <Iconify icon="eva:save-fill" color="primary.main" />
            </IconButton>
          </Tooltip>
          <Tooltip title={String(translate('global.cancel'))}>
            <IconButton onClick={onEditChange}>
              <Iconify icon="eva:close-fill" color="error.main" />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default DigitalAssetsEditRow;
