import { Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useLocales from '#/hooks/useLocales';

type EmptyDigitalAssetsProps = {
  isEmptyList: boolean;
  showForm: () => void;
  // downloadPasswordManagerTemplate: () => void;
  downloadList: () => void;
  preparingURL?: boolean;
};

export default function MainActions({
  isEmptyList,
  showForm,
  downloadList,
  // downloadPasswordManagerTemplate,
  preparingURL,
}: EmptyDigitalAssetsProps) {
  // const { translate } = useLocales();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ marginY: 4 }}
      spacing={3}
    >
      {/* <Typography */}
      {/*  variant="subtitle1" */}
      {/*  color="primary.main" */}
      {/*  sx={{ cursor: 'pointer' }} */}
      {/*  onClick={downloadPasswordManagerTemplate} */}
      {/* > */}
      {/*  {String(translate('digitalAssets.requestOtherPasswordManager'))} */}
      {/* </Typography> */}
      {isEmptyList ? (
        <AddItemButton add={showForm} />
      ) : (
        <DownloadListButton
          downloadList={downloadList}
          preparingURL={preparingURL}
        />
      )}
    </Stack>
  );
}

type AddItemButtonProps = {
  add: () => void;
};

export const AddItemButton = ({ add }: AddItemButtonProps) => {
  const { translate } = useLocales();
  return (
    <Button variant="contained" onClick={add}>
      <Typography variant="subtitle1" fontWeight="light">
        {String(translate('digitalAssets.addItems'))}
      </Typography>
    </Button>
  );
};

type DownloadListButtonProps = {
  downloadList: () => void;
  preparingURL?: boolean;
};

export const DownloadListButton = ({
  downloadList,
  preparingURL,
}: DownloadListButtonProps) => {
  const { translate } = useLocales();

  return (
    <LoadingButton
      variant="outlined"
      size="small"
      onClick={downloadList}
      loading={preparingURL}
    >
      <Typography variant="subtitle1" fontWeight="light">
        {String(translate('digitalAssets.downloadList'))}
      </Typography>
    </LoadingButton>
  );
};
