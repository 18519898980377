import { StackProps } from '@mui/material/Stack';
import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';

type Props = StackProps & {
  filters: {
    search: string;
    asset_type: string;
  };
  onFilters: (name: string, value: string | string[]) => void;
  onResetFilters: VoidFunction;
  results: number;
};

export default function DigitalAssetsFilterResults({
  filters,
  onFilters,
  onResetFilters,
  results,
  ...other
}: Props) {
  const { translate } = useLocales();

  return (
    <Stack spacing={1.5} {...other}>
      <Button
        color="error"
        onClick={onResetFilters}
        startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
      >
        <Typography>{String(translate('global.removeFilter'))}</Typography>
      </Button>
    </Stack>
  );
}
